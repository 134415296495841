<template>
    <div class="m-5 grid grid-cols-9 bg-white divide-x border rounded-sm">
        <div class="col-span-3 rounded-sm p-5">
            <div>Customer Name</div>
            <div class="text-lg font-medium">{{customer.name}}</div>
            <ul class="my-5 flex">
                <li class="flex" v-for="(parent, i) in parents" :key="i">
                    <router-link :to="'/customers/detail/'+parent._id" class="text-teal-500 hover:text-teal-600">{{parent.name}}</router-link>
                    <div v-if="parents.length > 0 && i < parents.length -1"><i class="icon-arrow-right22"></i></div>
                </li>
            </ul>
            <div class="mb-5">
                <div class="text-lg font-light">Address</div>
                <div v-if="customer.address">
                    <div v-if="customer.address.street" class="ml-3 border-b mb-1">{{customer.address.street}}</div>
                    <div v-if="customer.address.street2" class="ml-3 border-b mb-1">{{customer.address.street2}}</div>
                    <div v-if="customer.address.subdistrictName || customer.address.cityName || customer.address.zip" class="ml-3 border-b mb-1">{{customer.address.subdistrictName}} {{customer.address.cityName}} {{customer.address.zip}}</div>
                    <div v-if="customer.address.provinceName" class="ml-3 border-b mb-1">{{customer.address.provinceName}}</div>
                </div>
            </div>
            <div class="mb-5">
                <div class="text-lg font-light">Contact</div>
                <div v-if="customer.contact" class="ml-5">
                    <div class="grid grid-cols-4 border-b mb-1">
                        <div class="col-span-1">Phone</div>
                        <div class="col-span-3"> {{customer.contact.phone}}</div>
                    </div>
                    <div class="grid grid-cols-4 border-b mb-1">
                        <div class="col-span-1">Mobile</div>
                        <div class="col-span-3"> {{customer.contact.mobile}}</div>
                    </div>
                    <div class="grid grid-cols-4 border-b mb-1">
                        <div class="col-span-1">Email</div>
                        <div class="col-span-3"> {{customer.contact.email}}</div>
                    </div>
                    <div class="grid grid-cols-4 border-b mb-1">
                        <div class="col-span-1">Website</div>
                        <div class="col-span-3"> {{customer.contact.website}}</div>
                    </div>
                </div>
            </div>
            <div class="mb-5">
                <div class="text-lg font-light">NPWP</div>
                <div class="ml-5">{{customer.npwp}}</div>
            </div>
            <div class="mb-5">
                <div class="text-lg font-light">TAGS</div>
                <div class="ml-5 flex space-x-1">
                    <div v-for="(tag, i) in customer.tags" :key="i" class="bg-teal-500 text-teal-50 rounded-sm px-1">{{tag}}</div>
                </div>
            </div>
            <div class="mb-5">
                <div class="text-lg font-light">REMARKS</div>
                <div c n lass="ml-5">{{customer.remarks}}</div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="flex whitespace-nowrap cursor-pointer border-b relative">
                <div @click="onTab1('overview')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab1 =='overview'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">OVERVIEW</div>
                <div @click="onTab1('contact')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab1 =='contact'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">CONTACT & ADDRESS</div>
                <div @click="onTab1('sales')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab1 =='sales'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">SALES & PURCHASE</div>
                <div @click="onTab1('remarks')" :class="{'border-red-500 text-red-500 hover:border-red-500': tab1 =='remarks'}" class="border-b-2 px-5 inline-block text-center pt-3 pb-2 border-transparent hover:border-gray-200 hover:text-red-600">REMARKS</div>
            </div>
            
            <!-- OVERVIEW -->
            <div v-show="tab1 == 'overview'" class="p-5">
                <div class="flex items-center justify-between mb-5">
                    <div class="font-semibold">
                        PRODUCT
                    </div>
                    <div class="flex  text-right">
                        <div class="mr-5">
                            <div class="text-gray-400 leading-none">CREATED BY</div>
                            <div>{{user}}</div>
                        </div>
                        <div class="mr-5">
                            <div class="text-gray-400 leading-none">CREATED AT</div>
                            <div>{{getDate(customer.createdAt)}}</div>
                        </div>
                        <div>
                            <div class="text-gray-400 leading-none">UPDATED AT</div>
                            <div>{{getDate(customer.updatedAt)}}</div>
                        </div>
                    </div>
                </div>
                <table class="w-full">
                    <tbody class="divide-y border-t">
                        <tr v-for="(product, i) in products" :key="i">
                            <td class="py-2 px-3">{{product.name}}</td>
                            <td class="text-right">{{product.sum}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- CONTACT & ADDRESS -->
            <div v-show="tab1 == 'contact'" class="p-5">
                <ul>
                    <li v-for="(contact, i) in contacts" :key="i" class="border rounded-sm px-5 py-3 mb-2">
                        <div class="flex justify-between items-center mb-2">
                            <div class="font-semibold text-base leading-none">{{contact.name}}</div>
                            <div class="flex items-center">
                                <div class="leading-none mr-2">Invoice Address</div>
                                <div>
                                    <i class="icon-file-spreadsheet"></i>
                                </div>
                            </div>
                        </div>
                        <div class="grid grid-cols-2 gap-5">
                            <div>
                                <div class="font-light border-b mb-2">Address</div>
                                <div v-if="contact.address">{{contact.address.street}}</div>
                                <div v-if="contact.address">{{contact.address.street2}}</div>
                                <div>
                                    <span v-if="contact.address">{{contact.address.subdistrictName}}</span>
                                    <span v-if="contact.address">{{contact.address.cityName}}</span>
                                    <span v-if="contact.address">{{contact.address.provinceName}}</span>
                                </div>
                                <div>Jawa Barat</div>
                            </div>
                            <div>
                                <div class="font-light border-b mb-2">Contact</div>
                                <table class="w-full">
                                    <tr>
                                        <td>Mobile</td>
                                        <td>: <span v-if="contact.contact">{{contact.contact.mobile}}</span></td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>: <span v-if="contact.contact">{{contact.contact.email}}</span></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-show="tab1=='sales'">
                <div class="p-5">
                    <div class="grid grid-cols-4 gap-5 mb-5">
                        <div class="border-b">
                            <div class="text-base font-semibold">Rp 1,000,000,000</div>
                            <div>UNIINVOICED</div>
                            <div class="text-xs text-gray-400">1 Sales</div>
                        </div>
                        <div class="border-b">
                            <div class="text-base font-semibold">Rp 1000,000,000</div>
                            <div>UNPAID</div>
                            <div class="text-xs text-gray-400">0 Invoices</div>
                        </div>
                        <div class="border-b text-red-500">
                            <div class="text-base font-semibold">Rp 1000,000,000</div>
                            <div>OVERDUE</div>
                            <div class="text-xs text-red-400">1 Invoices</div>
                        </div>
                        <div class="border-b text-green-500">
                            <div class="text-base font-semibold">Rp 1000,000,000</div>
                            <div>PAID</div>
                            <div class="text-xs text-green-400">1 Invoices</div>
                        </div>
                    </div>
                    <table class="w-full">
                        <thead>
                            <tr class="bg-green-500">
                                <th class="text-green-50 px-3 py-2">SALES ORDER #</th>
                                <th class="text-green-50 px-3 py-2 text-right">TOTAL</th>
                                <th class="text-green-50 px-3 py-2">INVOICE STATUS</th>
                                <th class="text-green-50 px-3 py-2">DATE CREATED</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y">
                            <tr v-for="(sale, i) in sales" :key="i">
                                <td class="px-3 py-2">
                                    <router-link to="/" class="text-blue-500">{{sale.salesNo}}</router-link>
                                </td>
                                <td class="px-3 py-2 text-right">{{Intl.NumberFormat('en-US',{minimumFractionDigits: 2}).format(sale.grandTotal)}}</td>
                                <td class="px-3 py-2">
                                    <div class="text-xs font-medium bg-blue-400 text-blue-50 inline-block rounded-sm px-1 py-px">{{sale.invoiceStatus}}</div>
                                </td>
                                <td>{{getDate(sale.salesCreated)}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadcrumbManager from '../../mixins/breadcrumbsManager.vue'
import {EventBus} from '../../event-bus'
import axios from 'axios'
import {moment} from '../../moment'
export default {
    data () {
        return {
            tab1: 'overview',
            tab: 'contact',
            displayName: '',
            customer:'',
            contacts: [],
            user: '',
            parents: [],
            products: [],
            sales: []
        }
    },
    mixins: [BreadcrumbManager],
    mounted () {
        this.getData(this.$route.params.id)
        this.setButtonText('EDIT')
        this.setDiscard(true)
        this.setDiscardText('CREATE')
        EventBus.$on('onClick', this.onEdit)
        EventBus.$on('onCancel', this.onCreate)
    },
    destroyed () {
        EventBus.$off('onClick', this.onEdit)
        EventBus.$off('onCancel', this.onCreate)
    },
    methods: {
        onTab1 (i) {
            this.tab1 = i
        },
        onTab (i) {
            this.tab = i
        },
        getData (id) {
            axios.get('/customers/detail/'+id)
            .then(res => {
                this.customer = res.data.customer.root
                this.parents = res.data.customer.parents
                this.displayName = res.data.customer.displayName
                this.user = res.data.customer.user
                this.products = res.data.products
                this.contacts = res.data.contacts
                this.sales = res.data.sales
                this.replaceBreadcrumb({
                    find: 'Customers',
                    replace: {text: 'Customers', path: '/customers'}
                })
                this.pushBreadcrumb({
                    text: this.displayName
                })
            })
        },
        onEdit () {
            this.$router.push('/customers/edit/'+this.customer._id);
        },
        onCreate () {
            this.$router.push('/customers/new')
        },
        getDate (d) {
            return moment(d)
        }
    },
    beforeRouteEnter (to, from ,next) {
        next(vm => {
            vm.spliceBreadcrumb({path: to.path})
        })
    },
    beforeRouteUpdate (to, from, next) {
        this.replaceBreadcrumb({
            find: this.displayName,
            replace: {text: this.displayName, path: this.$route.path}
        })
        next()
    }
}
</script>